/** @jsx jsx */
/* eslint-disable jsx-a11y/no-autofocus, react/jsx-no-target-blank */
import { jsx } from "theme-ui"
import React from "react"
import { Link, useStaticQuery, graphql, navigate } from "gatsby"
import { useCombobox } from "downshift"
import Fuse from "fuse.js"


import * as icons from "../utils/icons"
import { rhythm } from "../utils/typography"
import Logo from "./logo"

class Layout extends React.Component {
  render() {
    const { location, children, description } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <div>
        <div
          sx={{
            py: 3,
            color: "headerText",
            backgroundColor: "headerBackground",
          }}
        >
          {location.pathname === rootPath ? (
            <header
              sx={{
                mx: `auto`,
                maxWidth: "80%",
                fontSize: "30px", /*magnifying glass */
                px: [2, 4],
                pt: 4,
                pb: 2,
              }}
            >
              <Logo color="white" size={["36px", "48px"]} />
              <p sx={{ pt: 2, pb: 2, mb: 2, mt: 2, fontSize: [2, 45] }}>   
                {description }
              </p>
            </header>
          ) : (
            <header
              sx={{
                marginLeft: `auto`,
                marginRight: `auto`,
                maxWidth: "80%",
                fontSize: "30px", /* doesnt do anything */
                px: [2, 4],
                pt: 4,
                pb: 2,
              }}
            >
              <h3
                sx={{
                  mt: 0,
                  mb: 3,
                }}
              >
                <Link
                  sx={{
                    boxShadow: `none`,
                    textDecoration: `none`,
                    color: `logoColor`,
                    "&:hover": {
                      textDecoration: "none",
                      color: "logoColor",
                    },
                  }}
                  to={`/`}
                >
                  <Logo color="black" size={["36px", "48px"]} />
                </Link>
              </h3>
              {location.pathname === rootPath && <p>{description}</p>}
            </header>
          )}
        </div>
        <div
          style={{
            background: "#F2F2F2",
          }}
        >
          <main
            sx={{
              mx: `auto`,
              maxWidth: '80%',
              px: [2, 4],
              py: [3],
            }}
          >
            {children}
          </main>
        </div>
        <footer
          sx={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(30),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            textAlign: "center",
            color: "footerTextColor",
            fontSize: 1,
          }}
        >
          <Logo color="currentColor" size="60px" />
          <div sx={{ mt: 2 }}>
            Built with
            {` `}
            {/*
              PLEASE DO NOT REMOVE THIS LINK.

              A lot of unpaid time is spent on making and maintaining the 
              center. Nothing is expected in return. Keeping this link here
              is the only small thing asked in return. So please don't remove it.

              You are amazing for keeping it here, thank you.
            */}
            <a
              href="https://help.dferber.de"
              target="_blank"
              sx={{
                color: "footerTextColor",
                textDecoration: "underline",
                "&:hover": {
                  color: "footerTextHoverColor",
                },
              }}
            >
              Dom's Help Center
            </a>
          </div>
        </footer>
      </div>
    )
  }
}

export default Layout
